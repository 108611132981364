<script setup>
	const { anchorId, headline, cards, attributes } = defineProps({
		anchorId: {
			type: String,
			required: false,
			default: '',
		},
		headline: {
			type: String,
			default: 'Default Headline',
		},
		button: {
			type: Object,
			default: () => ({}),
		},
		cards: {
			type: Array,
			default: [],
			required: false,
		},
		attributes: {
			type: Object,
			default: () => ({}),
		},
	});

	const heights = [473, 310, 400];
	const widths = [670, 440, 567];
</script>

<template>
	<section :id="anchorId" class="block block-three-cards-with-links bg-gray-100" tabindex="-1">
		<div class="container">
			<header v-aos>
				<ProjectTitle v-if="headline" :title="headline" />
				<ProjectButton :button="button" version="red" />
			</header>
			<div class="image-grid-wrapper">
				<ul class="image-grid" v-if="cards?.length">
					<li v-for="(card, index) in cards" :key="index" class="resource-card-wrapper" v-aos>
						<FragmentsCardLink
							v-if="card && index + 1"
							:url="card.url"
							:image="card.image"
							:title="card.title"
							class="resource-card"
							:data-position="index + 1"
							:height="heights[index]"
							:width="widths[index]"
						/>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-three-cards-with-links {
		background-color: var(--gray-100);

		header {
			position: relative;
			z-index: 1;
			margin-bottom: 30px;

			// Animations
			opacity: 0;
			transform: translateY(2rem);
			transition: 500ms ease-in-out;

			&.v-aos-entered {
				opacity: 1;
				transform: translateY(0);
			}

			@media (min-width: 850px) {
				margin-bottom: -110px;
			}

			@media (min-width: $desktop) {
				margin-bottom: -120px;
			}

			.title-wrapper {
				max-width: 840px;
				margin-bottom: 30px;

				h2.h1 {
					margin-bottom: 0;
				}
			}
		}

		.image-grid {
			@media (min-width: 850px) {
				display: grid;
				grid-template-columns: repeat(12, 8.33%);
				grid-auto-rows: minmax(25px, auto);
			}
			.resource-card {
				z-index: 0;
				position: relative;
				display: flex;
				flex-direction: column;

				transition: 500ms ease-in-out;

				.content-wrapper {
					transition: 500ms ease-in-out 250ms;
				}

				&.card-link {
					.content-wrapper {
						z-index: 1;
						width: fit-content;
						margin-top: -40px;
						margin-bottom: 0;
						padding: 20px 20px 20px;
						background-color: var(--white);
						color: var(--black);

						@media (min-width: 850px) {
							margin-top: -50px;
							padding: 30px;
						}

						@media (min-width: 1024px) {
							margin-top: -60px;
							padding: 40px;
						}

						.card-title {
							margin-bottom: 0;
						}
					}
					&:hover {
						.content-wrapper {
							transform: translate(0, -1rem);
						}
					}
				}
			}
		}

		.resource-card-wrapper {
			width: 100%;
			&:not(:nth-of-type(3)) {
				margin-bottom: 30px;
			}

			.resource-card .content-wrapper {
				margin-right: 30px;
			}

			@media (min-width: 850px) {
				margin-bottom: 0;
				margin-bottom: 30px;
			}

			&:nth-of-type(1) {
				aspect-ratio: 1.7;

				@media (min-width: 850px) {
					grid-row-start: 1;
					grid-column: 7 / span 6;
					max-width: 670px;
				}

				.resource-card .content-wrapper {
					max-width: 440px;
				}
			}
			&:nth-of-type(2) {
				aspect-ratio: 1.15;

				@media (min-width: 850px) {
					grid-column: 6 / span 5;
					grid-row: 9 / span 8;
					max-width: 575px;
				}

				.resource-card .content-wrapper {
					max-width: 440px;
				}
			}
			&:nth-of-type(3) {
				@media (min-width: 850px) {
					aspect-ratio: 0.75;
					grid-column: 1 / span 4;
					grid-row-start: span 17;
					align-self: center;
					max-width: 440px;
				}

				.resource-card .content-wrapper {
					max-width: 345px;
				}
			}
		}

		.container {
			.v-aos-before-enter {
				.resource-card {
					// clip-path: inset(100% 0 0 100%);
					filter: grayscale(100%);
					.content-wrapper {
						transform: translateY(2rem);
						opacity: 0;
					}
				}
			}
			.v-aos-entered {
				.resource-card {
					filter: grayscale(0%);
					.content-wrapper {
						transform: translateY(0);
						opacity: 1;
					}
				}
			}
		}
	}
</style>
